<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" class="tabsWrap" @change="handleTabs">
      <el-radio-button :label="1">待收货({{ table.num1 }})</el-radio-button>
      <el-radio-button :label="4">全部收货({{ table.num4 }})</el-radio-button>
      <el-radio-button :label="2">已关闭({{ table.num2 }})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="采购单号" prop="order_no">
          <el-input
            size="mini"
            v-model.trim="table.params.order_no"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品名称" prop="goods_name">
          <el-input
            size="mini"
            v-model.trim="table.params.goods_name"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="交货日期" prop="delivery_date">
          <el-date-picker
            v-model="table.params.delivery_date"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
             @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="收货完成日期" prop="date" v-if="table.params.status == 4">
          <el-date-picker
            v-model="table.params.date"
            type="date"
            placeholder="选择日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
             @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <!-- 超级管理员才有地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            clearable
            filterable
            style="width:100%;"
             @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="table.params.merchant_id" filterable placeholder="请选择" clearable @focus="getMerchantSel" @change="changeMerchant">
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="食堂名称" prop="address_id">
          <el-select v-model="table.params.address_id" filterable placeholder="请输入" clearable @focus="getStoreSel" @change="onSearch">
            <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="供应商/采购员" prop="supplier_id">
          <el-select v-model.trim="table.params.supplier_id" filterable id="bind_id" ref="elementSelect" @input.native="trimInput" clearable placeholder="请输入" @focus="getSupplierSel" @change="onSearch">
            <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="采购状态" prop="is_send" v-if="table.params.status == 1">
          <el-select v-model="table.params.is_send" filterable id="is_send" clearable placeholder="请输入" @change="onSearch">
            <el-option label="待发货" :value="2" ></el-option>
            <el-option label="待收货" :value="1" ></el-option>
            <el-option label="部分收货" :value="3" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-plus" @click="showAdd">添加采购单</el-button>
        <!-- 已关闭的不可以导出 -->
        <el-button
          v-if="table.params.status != 2"
          :size="subUnitSize"
          type="primary"
          plain
          @click="mergeexportData('/admin/purchase_order/mergeExport')"
          >合并导出</el-button
        >
        <!-- <el-button :size="subUnitSize" type="primary" plain @click="batchReceive">批量收货</el-button> -->
      </div>
    </div>
    <VTable
      has-pagionation
      :field="toStr({1:field,4:field2,2:field3},table.params.status)"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <!-- 采购单号 -->
      <template v-slot:order_no="{row}">
        <el-link type="primary" @click="showDetail(row)">{{row.order_no}}</el-link>
      </template>
      <template v-slot:source="{row}">
        <span>{{row.source == 1 ? '销售订单' : '平台自采'}}</span>
      </template>
      <!-- 订单状态 -->
      <template v-slot:distr_status="{row}">
        <span>{{ toStr({0:'待分拣', 1:'待配送', 2:'待送达', 3:'已完成', 4:'已取消'},row.order.status)}}</span>
      </template>
      <!-- status：采购状态 -->
      <template v-slot:status="{row}">
        <span>{{ toStr({0:'待发货',1:'待收货', 2:'已关闭', 3:'部分收货', 4:'全部收货'},row.status)}}</span>
      </template>
      <template v-slot:action="{row}">
        <!-- 待发货的可以关闭、编辑和修改供应商（待收货也加编辑操作），发货了就不能关闭，编辑和修改供应商 -->
        <template v-if="row.status == 0 || row.status == 1">
          <el-button type="text" @click="showEdit(row)">编辑</el-button>
        </template>
        <el-button type="text" v-if="row.status == 0 || row.status == 1 || row.status == 3" @click="showReceive(row)">收货</el-button>
        <el-button type="text" v-if="row.status > 2" @click="plexportDataGoods(row)">导出</el-button>
        <el-button type="text" v-if="row.status != 2" @click="handleShare(row)" :loading="loading">分享</el-button>
        <el-button type="text" @click="showAdd(row)" v-if="row.status > 1">复制</el-button>
        <el-button type="text" @click="showRejected(row)" v-if="row.status == 4">退货</el-button>

        <el-dropdown @command="handleCommand($event,row)" style="margin-left:10px"  v-if="row.status == 0 || row.status == 1">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="export">导出</el-dropdown-item>
            <el-dropdown-item command="copy">复制</el-dropdown-item>
            <el-dropdown-item command="supplier" v-if="row.status == 0">修改供应商</el-dropdown-item>
            <el-dropdown-item command="cancel" v-if="row.status == 0">关闭</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </VTable>

    <!-- 批量收货 -->
    <el-dialog
      title=""
      :visible.sync="dialogVisibleForm"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      center>
      <el-alert
        title="提示：您可将同一供应商同一天交货日期且未完成收货的采购单一起登记收货"
        type="warning"
        style="margin-bottom:20px;"
        :closable="false">
      </el-alert>
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-position='right'
        label-width="100px"
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="供应商" prop="supplier_id">
          <el-select v-model.trim="form.data.supplier_id" filterable clearable placeholder="请输入">
            <el-option v-for="item in supplierOpt" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交货日期" prop="delivery_date">
          <el-date-picker
            v-model="form.data.delivery_date"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click="confirm">开始收货</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 新增 -->
    <add ref="add" @refresh="getTable"></add>
    <!-- 编辑 -->
    <edit ref="edit" @refresh="getTable"></edit>
    <!-- 采购单详情 -->
    <detail ref="detail" @refresh="getTable"></detail>
    <!-- 收货 -->
    <receive ref="receive" @refresh="getTable"></receive>
    <!-- 退货 -->
    <reject ref="reject" @refresh="getTable"></reject>
    <!-- 修改供应商 -->
    <supplier-edit
      ref="supplierEdit"
      :setDataApi="setDataApi"
      @refresh="getTable"
    ></supplier-edit>

  </div>
</template>

<script>
import Add from './components/Created.vue'
import Edit from './components/Edit.vue'
import Detail from './components/Details.vue'
import Receive from './components/Receive.vue'
// import Receive from './components/Untitled.vue'
import Reject from './components/Reject.vue'
import SupplierEdit from '@/components/SupplierEdit.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
import { getStorage } from '@/storage'

export default {
  name: 'PurchaseIndex',
  components: {
    Edit,
    Add,
    Detail,
    Receive,
    Reject,
    SupplierEdit,
    VTable,
  },
  mixins: [mixinTable],
  computed:{
    ...mapGetters(['goodsTypeArr','areaArr'])
  },
  data() {
    return {
      setDataApi: '/admin/purchase_order/updateBuyer', // 修改供应商接口
      fileUrl: 'https://element.eleme.cn/#/zh-CN/component/form#form-item-methods',
      roleType: getStorage('role_type'),// 1:超级管理员，2:管理员, 3：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      loading: false,
      field: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "supplier_name", label: "供应商名称", width:'145', hidden: false },
        { name: "order_no", label: "采购单号", width:'190', hidden: false },
        { name: "source", label: "采购来源", width:'120', showTooltip: true, hidden: false},
        { name: "order_count", label: "商品项数/项", hidden: false},
        { name: "num", label: "下单数量", hidden: false},
        { name: "delivery_date", label: "计划交货日期", width:'110',  hidden: false},
        { name: "delivery_num", label: "已收货数量", hidden: false},
        { name: "delivery_money", label: "已收货金额", hidden: false},
        { name: "status", label: "采购状态", hidden: false},
        { name: "city_name", label: "所属地区", hidden: false},
        { name: "create_time", label: "创建时间",  width:'160', hidden: false},
        { name: "action", label: "操作",width: "200", fixed:"right", hidden: false }
      ],
      field2: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "supplier_name", label: "供应商名称", width:'145', hidden: false },
        { name: "order_no", label: "采购单号", width:'190', hidden: false },
        { name: "source", label: "采购来源", width:'120', showTooltip: true, hidden: false},
        { name: "order_count", label: "商品项数/项", hidden: false},
        { name: "num", label: "下单数量", hidden: false},
        { name: "delivery_date", label: "计划交货日期", width:'110',  hidden: false},
        { name: "delivery_num", label: "已收货数量", hidden: false},
        { name: "delivery_money", label: "已收货金额", hidden: false},
        { name: "status", label: "采购状态", hidden: false},
        { name: "city_name", label: "所属地区", hidden: false},
        { name: "create_time", label: "创建时间",  width:'160', hidden: false},
        { name: "complete_time", label: "完成时间",  width:'160', hidden: false},
        { name: "action", label: "操作",width: "180", fixed:"right", hidden: false }
      ],
      field3: [
        { name: "id", label: "ID", width:'80', hidden: false },
        { name: "supplier_name", label: "供应商名称", width:'145', hidden: false },
        { name: "order_no", label: "采购单号", width:'190', hidden: false },
        { name: "source", label: "采购来源", width:'120', showTooltip: true, hidden: false},
        { name: "order_count", label: "商品项数/项", hidden: false},
        { name: "num", label: "下单数量", hidden: false},
        { name: "delivery_date", label: "计划交货日期", width:'110',  hidden: false},
        { name: "delivery_num", label: "已收货数量", hidden: false},
        { name: "delivery_money", label: "已收货金额", hidden: false},
        { name: "status", label: "采购状态", hidden: false},
        { name: "city_name", label: "所属地区", hidden: false},
        { name: "create_time", label: "创建时间",  width:'160', hidden: false},
        { name: "cancel_time", label: "关闭时间",  width:'160', hidden: false},
        { name: "action", label: "操作",width: "80", fixed:"right", hidden: false }
      ],
      multipleSelection:[],
      table: {
        loading: false,
        params: {
          order_no: '', // 采购单号
          goods_name: '',
          status: 1, //状态 1:待采购 2:已关闭（已取消） 3:部分收货 4:全部收货（完成）
          delivery_date: '', // 收货日期
          date: '', // 收货完成日期
          address_id: '', // 下单食堂
          supplier_id: '', //采购员供应商
          area: [],
          province: '',
          city: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        num1: 0,//待收货数量
        num2: 0, //已关闭数量
        num4: 0, //全部收货数量
      },
      merchantArr:[], //客户
      storeArr: [], //食堂
      supplierArr: [], // 供应商列表/采购员
      supplierOpt: [], // 供应商列表
      dialogVisibleForm: false,
      form: {
        loading: false,
        data: {
          supplier_id: '', //供应商
          delivery_date:'', // 交货日期
        },
        rules: {
          supplier_id: [{ required: true, message:'请选择供应商', trigger: 'change' }],
          delivery_date: [{ required: true, message:'请选择交货日期', trigger: 'change' }],
        }
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _area = this.table.params.area;
      if(_area.length == 1) {
        this.table.params.province = _area[0]
      } else if(_area.length == 2) {
        this.table.params.province = _area[0]
        this.table.params.city = _area[1]
      } else {
        this.table.params.province = '';
        this.table.params.city = '';
      }
      this.table.loading = true;
      this.$http.get('/admin/purchase_order/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.num1 = res.data.num1;
          this.table.num2 = res.data.num2;
          this.table.num4 = res.data.num4;
        }
      })
    },
    // 新增、复制
    showAdd(row) {
      let dom = this.$refs.add;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null;
    },
    // 编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 采购单详情
    showDetail(row) {
      let dom = this.$refs.detail;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null;
    },
    // 收货
    showReceive(row) {
      let dom = this.$refs.receive;
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null;
    },
    // 退款
    showRejected(row) {
      let dom = this.$refs.reject;
      dom.toggle(true)
      if(!!row.id) {
        dom.getDetail(row)
      }
      dom = null;
    },
    // 修改供应商
    showSupplier(row) {
      let dom = this.$refs.supplierEdit;
      dom.toggle(true);
      dom.getDetail(row, 1); // 传1单条修改供应商/采购员
      dom = null;
    },
    // 取消
    handleCancel(row) {
      this.setConfirm(
        `确认关闭操作？`,
        {  id: row.id },
        "/admin/purchase_order/cancel"
      );
    },
    // 导出
    plexportDataGoods(row) {
      let url = '/admin/purchase_order/download'
      let str = ""
      let obj = {
        id: row.id
      }
      Object.keys(obj).forEach(item => {
          str += `&${item}=${obj[item]}`
      })
      // console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
    // 合并导出
    mergeexportData(url) {
      if (this.multipleSelection.length) {
        this.$confirm('同一个商品/下单单位/规格/供应商（采购员）合并为一行数据', '提示', {
          confirmButtonText: '确认导出',
          cancelButtonText: '取消',
        }).then(() => {
         let purchase_ids = this.multipleSelection.map((item) => {
            return item.id;
          }).join("|");
          let str = ""
          let obj = {
            purchase_ids
          }
          Object.keys(obj).forEach(item => {
              str += `&${item}=${obj[item]}`
          })
          console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
          window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)

        }).catch(() => {});
      } else {
        this.$message.warning("至少勾选一条数据");
      }
    },
    handleCommand(value,row) {
      console.log(value,"value")
      // return
      if(value == 'copy') { // 复制
        this.showAdd(row)
      } else if(value == 'supplier') { // 修改供应商
        this.showSupplier(row)
      } else if(value == 'cancel') { // 关闭
        this.handleCancel(row)
      } else if(value == 'export') {
        this.plexportDataGoods(row)
      }
    },
    // 复制分享
    handleShare(row) {
      // type 2:分享
      this.loading = true;
      this.$http.get('/admin/purchase_order/download',{params:{id:row.id,type:2}}).then(res => {
        if(res.code == 1) {
          console.log(res.data.path_url)
          const urlpath = res.data.path_url;
          this.$copyText(urlpath).then(() => {
            this.$message.success(`已复制${row.supplier_name}${row.delivery_date.replace(/-/g, "")}采购单明细`);
          });
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    // 获取供应商
    getSupplier() {
      this.$http.get('/admin/supplier/list', {params:{page:1, count:10000}}).then(res => {
        if(res.code === 1) {
          this.supplierOpt = res.data.list;
        }
      })
    },
    // 批量收货
    batchReceive() {
      this.dialogVisibleForm = true;
      this.getSupplier()
    },
    confirm() {
      let _params = {
        delivery_date: this.form.data.delivery_date, // 配送日期
        supplier_id: this.form.data.supplier_id, // 供应商/采购员
      }
      this.$http.get('/admin/purchase_order/infoByBind', { params: _params}).then(res => {
        if(res.code == 1) {
          if(res.data.list.length > 0) {
             window.open(this.$router.resolve({name: 'BatchReceive',query: _params }).href, '_blank');
          } else {
            this.$message.warning("暂无待收货单据，请检查")
          }
        }
      })
    
     
    }
  }
}
</script>

<style scoped lang="scss">
.el-dropdown-link {
  cursor: pointer;
  color: #2cb167;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.main-view {
  overflow: auto;
}
.main-view::-webkit-scrollbar {
  width:0px;
}
.main-view::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 0px;
}


</style>
